import React from 'react';
import { MdLocationPin } from 'react-icons/md';
import { Link } from 'react-router-dom';

const MeerInformatie = () => {

    const schermBreedte = window.Window;

  return (
    <div>
        <div className='flex justify-center'>
            <h1 className='text-5xl text-white'>kiteTripper</h1>
            <img src={require('../assets/kite.png')} alt='' className='mt-2 ml-18'/>
        </div>
        <div className='titel bg-sky-700 -mt-12 w-auto'></div>
        <Link to='/Home'>
            <div className='titel bg-sky-700 -mt-12 w-auto'>
                <div className='flex justify-center'>
                    <h1 className='text-5xl text-white'>kiteTripper</h1>
                </div>
                <div className='flex justify-center mt-4 text-1xl text-white'>
                    <h2>The app to kite with friends</h2>
                </div>
            </div>   
         </Link>
        <div className='lg:ml-24 mt-12 pointer-events-none'>
                <div className='flex justify-center'>
                    <img src={require('../assets/iphone_brouwersdam.png')} alt='' className='w-64 -mt-20 lg:mr-20'/>
                </div>
        </div>
        <div name='inhoud'>
            <div name='DeApp' className='-mt-12'>
                <div>
                    <h3 className='text-3xl flex justify-center'>De app</h3>
                </div>
                <div className='flex text-center text-lg mt-6 
                lg:ml-96 lg:mr-96'>
                    Met de kiteTripper app, kun je op elk
                    moment gaan kiteSurfen op verschillende Spots in heel Nederland. 
                    Dit doen we door een overzicht aan te 
                    bieden met veschillende kiteSurf trips.
                </div>
                {schermBreedte>640 && 
                <div className='flex text-center text-lg mt-6 ml-6'>
                    Ook kun je zelf een trip aanbieden voor
                    meer gezelligheid tijdens de trip of om een 
                    kleine vergoeding te krijgen voor de 
                    gemaakte kosten.
                </div>}
            </div>
            <div className='ml-8 mr-8 border-b mt-8'/>
            <div name='Spots' className='mt-4'>
                <div>
                    <h3 className='text-3xl flex justify-center'>Spots</h3>
                </div>
                <div className='flex justify-center text-center text-lg mt-6'>
                    Er zijn nu trips naar deze spots: 
                </div>
                <div className='flex justify-center text-xl mt-6'>
                    <MdLocationPin size={43} color={"1078AC"}/>
                    <p className='mt-2 ml-6'>Oostvoorne</p>
                </div>
                <div className='flex justify-center text-center text-xl'>
                    <MdLocationPin size={43} color={"1078AC"}/>
                    <p className='mt-2 ml-6'>Brouwersdam</p>
                </div>
            </div>
            <div className='flex justify-center mt-12 mb-12'>
            <Link to='/SpotToevoegen'>
                <button class="bg-sky-600 hover:bg-sky-700  text-white font-bold py-2 px-4 rounded">
                    Spot toevoegen
                </button>
        </Link>
            </div>

        </div>
        <div className="w-72 h-72 rounded-full bg-blue-500 opacity-10 absolute -bottom-24 -left-24 pointer-events-none"></div>
        <div className="w-72 h-72 rounded-full bg-blue-500 opacity-10 absolute -bottom-24 -right-24 pointer-events-none"></div>
        <div className="w-32 h-32 rounded-full bg-blue-500 opacity-10 absolute bottom-96 left-24 pointer-events-none"></div>
        <div className="w-52 h-52 rounded-full bg-blue-500 opacity-10 absolute top-32 right-24 pointer-events-none"></div>
    </div>
    
  )
}

export default MeerInformatie