import { getByTestId } from '@testing-library/react';
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {

  return (
    <div>
      <div className='flex justify-center'>
            <h1 className='text-5xl text-white'>kiteTripper</h1>
            <img src={require('../assets/kite.png')} alt='' className='mt-2 ml-18'/>
          </div>
        <div className='titel bg-sky-700 -mt-12 w-auto'>
          <div className='flex justify-center'>
            <h1 className='text-5xl text-white'>kiteTripper</h1>
          </div>
          <div className='flex justify-center mt-4 text-1xl text-white'>
            <h2>The app to kite with friends</h2>
          </div>
         </div>   
            <div className='flex text-4xl mt-8 justify-center'>
              <p>Comming</p>
              <p className='text-blue-800 pl-4'>Soon</p>
            </div>
            <div>
              <div className='flex justify-center mr-1 -mt-24'>
                <img src={require('../assets/iphone.png')} alt='' className='w-52 mt-28
                                                                          '/>
            </div>
        </div>

        <div className='flex justify-center text-center ml-16 mr-16 mt-6 text-2xl tracking-wider text-sky-900'>
            Kitesurfen waar je maar wilt wanneer je maar wilt met wie je maar wilt
        </div>

        <div className='flex justify-center mt-12'>
          <Link to='/MeerInformatie'>
            <button className="bg-sky-600 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded w-44">
              Meer informatie
            </button>
          </Link>
        </div>
        <div className='flex justify-center mt-6 pb-6'>
        <Link to='/NieuwsBriefInschrijven'>
          <button class="bg-sky-600 hover:bg-sky-700  text-white font-bold py-2 px-4 rounded w-44">
            Hou op de hoogte
          </button>
        </Link>
        </div>
        <div className='flex justify-center pb-12'>
        <Link to='/VraagFormulier'>
          <button class="bg-sky-600 hover:bg-sky-700  text-white font-bold py-2 px-4 rounded w-44">
            Of stel een vraag
          </button>
        </Link>
        </div>
        <div className="w-72 h-72 rounded-full bg-blue-500 opacity-10 absolute -bottom-24 -left-24 pointer-events-none"></div>
        <div className="w-72 h-72 rounded-full bg-blue-500 opacity-10 absolute -bottom-24 -right-24 pointer-events-none"></div>
        <div className="w-32 h-32 rounded-full bg-blue-500 opacity-10 absolute bottom-96 left-44 pointer-events-none"></div>
        <div className="w-52 h-52 rounded-full bg-blue-500 opacity-10 absolute top-32 right-24 pointer-events-none"></div>
</div>
  )
}

export default Home