import React from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { useState } from 'react';

const NieuwsBriefInschrijven = () => {

const [naam, setNaam] = useState('');
const [emailVanPersoon, setEmailVanPersoon] = useState('');

const verstuurEmail = () => {
 
    var templateParams = {
        inschrijving_naam: naam,
        inschrijving_email: emailVanPersoon,
    };

    if (templateParams.inschrijving_naam.length < 3) {alert('Vul een valide naam in')};
    if (templateParams.inschrijving_email.length < 3 || !templateParams.inschrijving_email.includes('@')) {alert('Vul een valide email in')};
    if (templateParams.inschrijving_naam.length > 2 && templateParams.inschrijving_email.length > 3 && templateParams.inschrijving_email.includes('@')) {
    
    emailjs.send('service_baf9dnb', 'template_oy3nq84', templateParams, '92EadVXGleGp-NVp0' ).then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
        alert('Je bent ingeschreven.')
     }, function(error) {
        console.log('FAILED...', error);
     });
}};


  return (
    <div>
        <div className='flex justify-center'>
            <h1 className='text-5xl text-white'>kiteTripper</h1>
            <img src={require('../assets/kite.png')} alt='' className='mt-2 ml-18'/>
        </div>
        <div className='titel bg-sky-700 -mt-12 w-auto'></div>
        <Link to='/Home'>
            <div className='titel bg-sky-700 -mt-12 w-auto'>
                <div className='flex justify-center'>
                    <h1 className='text-5xl text-white'>kiteTripper</h1>
                </div>
                <div className='flex justify-center mt-4 text-1xl text-white'>
                    <h2>The app to kite with friends</h2>
                </div>
            </div>   
         </Link>
        <div class="beschrijving" className='text-lg mt-8 ml-6 mr-6
        lg:ml-96 lg:mr-96'>
           Geef hier je email adres op om je in te schrijven op de nieuwsbrief. Je word dan op de hoogte gehouden van de nieuwtjes en de release date!
        </div>
        <div class='inhoud' className='m-2 ml-6 mt-12
        lg:ml-96 lg:mr-96'>
            <div class="mt-2">
                <label>Naam</label>
                <input onChange={event => setNaam(event.target.value) } type='text' placeholder="Vul hier je naam in" class="w-full mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div class="mt-2">
                <label>Email</label>
                <input onChange={event => setEmailVanPersoon(event.target.value) } type='text' placeholder="Vul hier je email adres in" class="w-full mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div className='flex justify-center mt-12'>
                <Link to='/Home'>
                    <button class="bg-sky-600 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded
                    lg:mr-20" onClick={()=>{verstuurEmail()}}>
                        Inschrijven
                    </button>
                </Link>
            </div>
        </div>
        <div className="w-72 h-72 rounded-full bg-blue-500 opacity-10 absolute -bottom-24 -left-24 pointer-events-none"></div>
        <div className="w-72 h-72 rounded-full bg-blue-500 opacity-10 absolute -bottom-24 -right-24 pointer-events-none"></div>
        <div className="w-32 h-32 rounded-full bg-blue-500 opacity-10 absolute bottom-96 left-24 pointer-events-none"></div>
        <div className="w-52 h-52 rounded-full bg-blue-500 opacity-10 absolute top-32 right-24 pointer-events-none"></div>
    </div>
  )
}

export default NieuwsBriefInschrijven