import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";

const SpotToevoegen = () => {

const navigator = useNavigate(); 
    
const [naamMelder, setNaamMelder] = useState('');
const [emailMelder, setEmailMelder] = useState('');
const [naamSpot, setNaamSpot] = useState('');
const [locatieSpot, setLocatieSpot] = useState('');
const [beschrijvingSpot, setBeschrijvingSpot] = useState('');

const verstuurAanvraagNieuweSpot = () =>{
    var templateParams = {
        naamMelder: naamMelder,
        emailMelder: emailMelder,
        naamSpot: naamSpot,
        locatieSpot: locatieSpot,
        beschrijvingSpot: beschrijvingSpot,
    };

    //Valideer input. Ik wil alleen zekerheid over spot naam en locatie
    if (templateParams.naamSpot.length < 3) {alert('De naam van de spot is niet correct of leeg')};
    if (templateParams.locatieSpot.length < 3) {alert('De locatie van de spot is onjuist of leeg')};

    //Verstuur email
    if (templateParams.naamSpot.length > 2 && templateParams.locatieSpot.length > 2) {
    emailjs.send('service_baf9dnb', 'template_9o580jt', templateParams, '92EadVXGleGp-NVp0' ).then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
        alert('De nieuwe spot is aangevraagd')
     }, function(error) {
        console.log('FAILED...', error);
     });
     
    navigator('/home');
    
    }
}

  return (
    <div>
        <div className='flex justify-center'>
            <h1 className='text-5xl text-white'>kiteTripper</h1>
            <img src={require('../assets/kite.png')} alt='' className='mt-2 ml-18'/>
        </div>
        <div className='titel bg-sky-700 -mt-12 w-auto'></div>
        <Link to='/Home'>
            <div className='titel bg-sky-700 -mt-12 w-auto'>
                <div className='flex justify-center'>
                    <h1 className='text-5xl text-white'>kiteTripper</h1>
                </div>
                <div className='flex justify-center mt-4 text-1xl text-white'>
                    <h2>The app to kite with friends</h2>
                </div>
            </div>   
         </Link>
        <div className='lg:ml-24 mt-12'>
                <div className='flex justify-center'>
                    <img src={require('../assets/iphone_brouwersdam.png')} alt='' className='w-64 -mt-20
                    lg:mr-20'/>
                </div>
        </div>
        <div>
                <h3 className='text-3xl -mt-12 flex justify-center'>Nieuwe spot </h3>
        </div>
        <div name="beschrijving" className='text-lg mt-6 ml-6
        lg:ml-96 lg:mr-96'>
            Op dit moment zijn we de ontwikkeling van de app aan het afronden. We gaan nu steeds meer en meer opzoek naar nieuwe, andere, en mooiere spots die we kunnen gaan aanbieden. Hieronder kun je een voorstel doen voor een spot waar jij graag naar toe gaat.
        </div>
        <div name='inhoud' className='m-2 mt-6 ml-6
        lg:ml-96 lg:mr-96'>
            <div>
                <label>Jouw naam</label>
                <input onChange={event => setNaamMelder(event.target.value)} type='text' placeholder="Jouw naam" class="w-full mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div className="mt-2">
                <label>Email</label>
                <input onChange={event => setEmailMelder(event.target.value)} type='text' placeholder="Je email adres" class="w-full mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div className="mt-2">
                <label>Naam van de spot</label>
                <input onChange={event => setNaamSpot(event.target.value)} type='text' placeholder="Naam van de spot" class="w-full mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div className="mt-2">
                <label>Locatie van de spot</label>
                <input onChange={event => setLocatieSpot(event.target.value)} type='text' placeholder="Locatie" class="w-full mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div className="mt-2">
                <label>Beschrijving van de spot</label>
                <input onChange={event => setBeschrijvingSpot(event.target.value)} type='text' placeholder="Beschrijving van de spot" class="w-full mt-2 px-4 py-24 pt-1 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div className='flex justify-center mt-12'>
                <button onClick={ () => {verstuurAanvraagNieuweSpot()} } class="bg-sky-600 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded
                lg:mr-20">
                    Verstuur
                </button>
            </div>
        </div>
        <div className="w-72 h-72 rounded-full bg-blue-500 opacity-10 absolute -bottom-24 -left-24 pointer-events-none"></div>
        <div className="w-72 h-72 rounded-full bg-blue-500 opacity-10 absolute -bottom-24 -right-24 pointer-events-none"></div>
        <div className="w-32 h-32 rounded-full bg-blue-500 opacity-10 absolute bottom-96 left-24 pointer-events-none"></div>
        <div className="w-52 h-52 rounded-full bg-blue-500 opacity-10 absolute top-32 right-24 pointer-events-none"></div>
    </div>
  )
}

export default SpotToevoegen