import React from 'react';
import './index.css';
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Paginas/Home';
import MeerInformatie from './Paginas/MeerInformatie';
import NieuwsBriefInschrijven from './Paginas/NieuwsBriefInschrijven';
import VraagFormulier from './Paginas/VraagFormulier';
import SpotToevoegen from './Paginas/SpotToevoegen';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(  <BrowserRouter>
  <Routes>
      <Route path="/" element={<App />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/MeerInformatie" element={<MeerInformatie />} />
      <Route path="/NieuwsBriefinschrijven" element={<NieuwsBriefInschrijven />} />
      <Route path="/VraagFormulier" element={<VraagFormulier />} />
      <Route path="/SpotToevoegen" element={<SpotToevoegen />} />
    </Routes>
</BrowserRouter>);