import React from 'react'
import { Link } from 'react-router-dom'

const VraagFormulier = () => {
  return (
    <div>
                <div className='flex justify-center'>
            <h1 className='text-5xl text-white'>kiteTripper</h1>
            <img src={require('../assets/kite.png')} alt='' className='mt-2 ml-18'/>
        </div>
        <div className='titel bg-sky-700 -mt-12 w-auto'></div>
        <Link to='/Home'>
            <div className='titel bg-sky-700 -mt-12 w-auto'>
                <div className='flex justify-center'>
                    <h1 className='text-5xl text-white'>kiteTripper</h1>
                </div>
                <div className='flex justify-center mt-4 text-1xl text-white'>
                    <h2>The app to kite with friends</h2>
                </div>
            </div>   
         </Link>
        <div class="beschrijving" className='text-lg mt-24 ml-6
        lg:ml-96 lg:mr-96'>
            Stel hier je vraag
        </div>
        <div class='inhoud' className='m-2 mt-12 ml-6
        lg:ml-96 lg:mr-96'>
            <div>
                <label>Naam</label>
                <input type='text' placeholder="Enter your input here" class="w-full mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div class="mt-2">
                <label>Email</label>
                <input type='text' placeholder="Enter your input here" class="w-full mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div class="mt-2">
                <label>Onderwerp</label>
                <input type='text' placeholder="Enter your input here" class="w-full mt-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div class="mt-2">
                <label>Vraag</label>
                <input type='text' placeholder="Enter your input here" class="w-full mt-2 px-4 py-24 pt-1 border rounded-lg text-gray-700 focus:outline-none focus:border-sky-600" />
            </div>
            <div className='flex justify-center mt-12'>
                <button class="bg-sky-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded
                lg:mr-20">
                    Verstuur
                </button>
            </div>
        </div>
        <div className="w-72 h-72 rounded-full bg-blue-500 opacity-10 absolute -bottom-24 -left-24 pointer-events-none"></div>
        <div className="w-72 h-72 rounded-full bg-blue-500 opacity-10 absolute -bottom-24 -right-24 pointer-events-none"></div>
        <div className="w-32 h-32 rounded-full bg-blue-500 opacity-10 absolute bottom-96 left-24 pointer-events-none"></div>
        <div className="w-52 h-52 rounded-full bg-blue-500 opacity-10 absolute top-32 right-24 pointer-events-none"></div>
    </div>
  )
}

export default VraagFormulier